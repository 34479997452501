@import 'assets/globalStyles/variables';

.Container {
  height: 100%;
  position: relative;
  width: 100%;
  @media only screen and (min-width: $sm) {
    display: inline-block;
    vertical-align: top;
  }
}

.Overlay {
  background: rgba(243, 247, 243, 0.7);
  border-radius: 15px;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 1;

  & h1 {
    color: $secondary;
  }
}

.Card {
  border-radius: 15px;
  box-shadow: 10px 10px 15px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
}

.CardText {
  justify-content: center;
  margin-top: 15px;
  overflow: scroll;
  padding: 2% 6%;
  padding-top: 0;
  text-align: justify;
  @media only screen and (min-width: $sm) {
    min-height: 80px;
  }

  :global {
    .ant-card-meta-title {
      background-color: white;
      overflow: hidden;
      white-space: pre-line;
      width: 90%;
      z-index: 3;

      h3 {
        box-orient: vertical;
        display: box;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
  }
}

.Description {
  @media only screen and (min-width: $sm) {
    height: 150px;
    overflow: scroll;
  }
}

.DescriptionSpace {
  box-shadow: none;
  justify-content: center;
  padding: 0 5% 5%;
  text-align: center;
  width: 100%;
}

.ShowMoreCollapse {
  :global {
    .ant-collapse-header {
      color: $primary !important;
      justify-content: center;
      padding-bottom: 8px !important;
      padding-top: 0 !important;
      text-align: center;
    }

    .ant-collapse-arrow {
      color: $primary;
    }
  }
}

.FullIcon {
  margin-right: -20px;
  margin-top: -30px;
  position: absolute;
  right: 0;
  width: 25%;
  z-index: 3;
  @media only screen and (max-width: $md) {
    width: 20%;
  }
  @media only screen and (max-width: $sm) {
    margin-top: -20px;
    width: 25%;
  }
}

.PackCardImage {
  border-radius: 15px 15px 0 0 !important;
  border-width: 0 0 10px;
  height: 240px;
  object-fit: cover;
  width: 100%;
}

.CardImage {
  border-radius: 15px 15px 0 0 !important;
  height: 240px;
  object-fit: cover;
  width: 100%;
}

.BodyHorizontalLine {
  padding-left: -6%;
}

.HorizontalLine {
  margin-top: 0;
}

.PriceDetail {
  align-items: flex-start;
  box-shadow: none;
  display: flex;
  flex-flow: column;
  padding: 2% 5%;

  & p {
    color: $stone-400;
    margin: 0;
  }
}

.Price {
  font-size: 32px;
  padding-block: 1vh;
}

.ClosedTemporaryContainer {
  background-color: rgba(242, 109, 49, 0.7);
  height: fit-content;
  margin-top: 12.5%;
  max-width: 100%;
}

.ClosedTemporaryText {
  color: $stone-50 !important;
  line-height: normal;
  margin-top: 2.5%;
  text-align: center;
  word-spacing: 200px;
}

.CountBadge {
  :global {
    .ant-badge-count {
      background-color: $secondary;
      border-radius: 100%;
      font-size: 16px;
      height: 26px;
      line-height: 26px;
      min-width: 26px;
      padding: 0 7.2px;
      width: 26px;
    }
  }
}

.ChildrenEnterForFree {
  font-size: 11px;
}
