@import 'assets/globalStyles/variables';

.Container {
  height: 300px;
  padding-left: 40px;
  position: relative;
}

.UpperText {
  color: $stone-50;
}

.Title {
  color: $stone-50;
  margin-top: 8px;
}

.MainTextContainer {
  bottom: 100px;
  height: 100%;
  left: 0;
  padding: 64px 40px 0 0;
  padding-inline: 40px;
  position: absolute;
  text-align: start;
  top: 100px;
  z-index: 1;
}

.Description {
  color: $stone-50;
  margin-top: 20px;
}

.MainImageContainer {
  left: 0;
  position: absolute;
  top: 0;
}

.MainImage {
  height: 300px;
  object-fit: cover;
  transform: scale(1);
  transform-origin: top;
  width: 100vw;
}
