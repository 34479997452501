@import 'assets/globalStyles/variables';

.Container {
  background-color: $primary-800;
  padding-bottom: 12px;
  padding-top: 20px;
}

.TicketSaleTitle {
  color: $stone-50;
  margin-bottom: 0;
}

.ActualParkAvailability {
  background-color: $stone-50;
  border-radius: 6px;
  cursor: default;
  padding: 8px 12px;
}

.TicketSaleButton {
  width: unset;
  @media only screen and (max-width: $sm) {
    width: 256px;
  }
}

.TicketDisclaimer {
  color: $stone-50;
  font-size: 14px;
  margin-top: 12px;
}

.SchedulesAndPricesContainer {
  display: none;
  @media only screen and (max-width: $md) {
    display: block;
  }
}

.SchedulesAndPricesButton {
  width: 256px;
}
