@import 'assets/globalStyles/variables';

.PlaceCard {
  border-radius: 10px;
  margin-left: 15px;
  margin-top: 15px;

  :global {
    .ant-card-cover img {
      border-radius: 10px 10px 0 0;
    }

    .ant-card-body {
      padding: 15px;
    }
  }
}

.PlaceImage {
  background-color: white;
  border-radius: 10px 10px 0 0;
  height: 15vh;
  object-fit: cover;
  width: 100%;
}

.TextContainer {
  background-color: white;
  border-radius: 0 0 10px 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
  overflow: hidden;
  padding: 4% 6% 8%;
  text-align: left;

  p {
    margin-bottom: 2px;
  }
}

.Tag {
  color: $stone-400;
  font-size: 12px;
  @media only screen and (min-width: $sm) {
    font-size: 10px;
  }
  @media only screen and (max-width: $sm) {
    display: none;
  }
}

.TitleContainer {
  align-items: center;
  display: flex;
  font-size: 16px;

  img {
    height: 14px;
  }
  @media only screen and (min-width: $sm) {
    font-size: 14px;
  }
}

.Title {
  box-orient: vertical;
  color: black;
  display: flex;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  min-height: 2ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;

  &:hover {
    height: auto; /* just added this line */
    -webkit-line-clamp: 2;
    line-clamp: 2;
    overflow: visible;
    white-space: normal;
  }
}

.LinkInfo {
  color: $primary-800;
  font-size: 12px;
  text-decoration: underline;
  @media only screen and (min-width: $sm) {
    font-size: 10px;
  }
}
