@import 'assets/globalStyles/variables';

.Container {
  background: $primary;
  padding-left: 5%;
  padding-right: 5%;
  text-align: center;
  width: 100%;
  z-index: 100;
  @media only screen and (max-width: $sm) {
    padding-left: 2%;
    padding-right: 2%;
  }
}

.LogoContainer {
  @media only screen and (max-width: $sm) {
    justify-content: left;
    text-align: start;
  }
}

.ButtonsContainer {
  align-items: center;
  height: 100%;
  justify-content: center;
  text-align: right;
}

.Logo {
  height: auto;
  max-width: 150px;
  position: relative;
  width: 100%;
}

.SchedulesAndPricesButton {
  @media only screen and (max-width: $md) {
    display: none;
  }
}

.Space {
  @media only screen and (max-width: $md) {
    gap: 0 !important;
    height: 100%;

    :global {
      display: grid;
    }
  }
}
