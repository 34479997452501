@import 'assets/globalStyles/variables';

.Form {
  border-radius: 10px;
  box-shadow: 0 3px 8px rgba(50, 50, 50, 0.17);
  margin: 2% 0;
  padding: 4% 4% 1.5%;
  text-align: left;
  @media only screen and (max-width: $sm) {
    padding: 50px 25px 20px;
  }
}

.FormSelect {
  :global {
    .ant-select-selector {
      align-items: center !important;
      border: none !important;
      border-radius: 5px !important;
      font-size: 16px !important;
      height: 53px !important;
      padding-left: 20px !important;
    }

    .ant-select-item-option-content {
      font-size: 16px !important;
      padding-left: 20px !important;
    }

    .ant-select-selection-search {
      align-items: center !important;
      display: flex;
      padding-left: 10px !important;
    }
  }
}

.FormInput {
  border: none;
  border-radius: 5px;
}

.FormInput:focus {
  border-color: $primary;
}

.Title {
  color: $stone-800;
  font-size: xx-large;
  font-weight: bold;
  margin-top: 5%;
  @media only screen and (max-width: $sm) {
    font-size: x-large;
    padding: 1.5vh 3vw 0;
  }
}

.SubTotal {
  font-weight: bold;
}

.ParkPolicy {
  margin-bottom: 0;
}

.Checkbox {
  :global {
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: $primary;
      border-color: $primary;
    }
  }
}

.Href {
  color: $primary;

  &:hover {
    text-decoration: underline;
  }
}

.BillingWarning {
  color: $secondary;
}
