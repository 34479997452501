@import 'assets/globalStyles/variables';

.Collapse {
  background-color: $stone-50;
  border: none;
  border-radius: 10px;
  box-shadow: 0 3px 8px rgba(50, 50, 50, 0.17);
  margin-bottom: 2%;
  margin-top: 2%;
  padding: 3% 5%;
  text-align: left;
  @media only screen and (max-width: $md) {
    margin-top: 5%;
  }
}

.FullWidth {
  width: 100%;
}

.Spin {
  :global {
    .ant-spin-dot-item {
      background-color: $primary-800;
    }
  }
}

.CollapseTitle {
  color: $stone-800;
  @media only screen and (max-width: $sm) {
    font-size: 20px;
    text-align: center;
  }
}

.CollapseIcon {
  font-size: 20px;
}

.PriceDetail {
  color: $stone-800;
}

.RadioButton {
  padding: 1%;
  width: 23%;

  & span {
    border-color: $primary;
  }
  @media only screen and (max-width: $sm) {
    width: 47%;
  }
}

.TooltipContainer {
  margin-left: 5px;
}

.MoreInformationContainer {
  text-align: center;
}

.Href {
  color: $eastern-blue-300;
}
