@import 'assets/globalStyles/variables';

.ConfigureTitle {
  color: $primary;
  line-height: 1.5;
  padding-top: 3vh;
  @media only screen and (min-width: $sm) {
    font-size: 31px;
  }
}

.ConfigureTitleRow {
  padding-inline: 48px;
  text-align: start;
}

.CardFormRow {
  padding-inline: 48px;
  @media only screen and (max-width: $sm) {
    padding-inline: 24px;
  }
}

.ShowMoreCollapse {
  background-color: $stone-100;

  :global {
    margin-top: 12px !important;

    .ant-collapse-header {
      color: $primary !important;
      font-size: large;
      justify-content: center;
      padding-bottom: 5px !important;
      text-align: center;
    }

    .ant-collapse-arrow {
      color: $primary;
    }
  }
}

.DetailsAndConditionsText {
  text-align: start;
}
