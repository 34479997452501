@import 'assets/globalStyles/variables';

.Title {
  color: black;
  margin-top: 2%;
  text-align: start;
  @media only screen and (max-width: $sm) {
    margin-top: 20px;
  }

  :global {
    .ant-checkbox {
      margin-right: 10px;
    }
  }
}

.GiftCard {
  margin-top: 1%;
  @media only screen and (max-width: $sm) {
    margin-top: 10px;
  }
}

.Input {
  background-color: transparent;
  border-color: $primary;
  border-radius: 5px;
  border-width: 2px;
  height: 40px;

  & input {
    background-color: transparent;
  }
}

.SubmitButton {
  width: 100%;
  @media only screen and (max-width: $sm) {
    margin: 3% 0;
  }
}

.IncorrectMessage {
  color: $cardinal-600;
}
