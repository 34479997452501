@import 'assets/globalStyles/variables';

.Container {
  margin-bottom: 10vh;
}

.Title {
  color: $primary;
  font-weight: 500;
}

.Carousel {
  margin-bottom: 3vh;
}

.CardContainer {
  padding: 0 1vw;
}

.ImageContainer {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.BackGroundImageContainer {
  display: inline-block;
  height: 100%;
  position: relative;
  width: 100%;
}

.BackGroundImageContainer::after {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.56) 0%, white 100%);
  content: '';
  display: inline-block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.Image {
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
