@import 'assets/globalStyles/variables';

.Container {
  height: 150px;
  position: relative;
  width: 150px;
}

.TextContainer {
  bottom: 14px;
  left: 0;
  padding-inline: 8px;
  position: absolute;
  text-align: start;
  width: 100%;
  z-index: 1;
}

.TitleContainer {
  white-space: break-spaces;
}

.Title {
  color: $stone-50;
  overflow-wrap: break-word;
}

.Icon {
  height: 28px;
}

.ImageContainer {
  bottom: 0;
  left: 0;
  position: absolute;
}

.Gradient {
  background: linear-gradient(180deg, rgba(90, 76, 57, 0) 0%, rgba(90, 76, 57, 0.8) 100%);
  border-radius: 10px;
  height: 150px;
  width: 150px;
  z-index: 1;
}

.MainImage {
  border-radius: 10px;
  height: 150px;
  object-fit: cover;
  width: 150px;
}
