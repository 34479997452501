@import 'assets/globalStyles/variables';

.Card {
  border-radius: 10px;
  box-shadow: 0 3px 8px rgba(50, 50, 50, 0.17);
  padding: 4% 6%;
  text-align: justify;
}

.Title {
  color: $stone-800;
  margin-bottom: 0;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: normal;
}

.Description {
  p {
    color: black;
    margin-bottom: 0;
  }
}

.ShowMoreCollapse {
  :global {
    .ant-collapse-header {
      color: $primary !important;
      justify-content: center;
      padding-block: 6px !important;
      text-align: center;
    }

    .ant-collapse-arrow {
      color: $primary;
    }
  }
}

.ShowMoreCollapse {
  :global {
    .ant-collapse-header {
      color: $primary !important;
      justify-content: center;
      padding-block: 6px !important;
      text-align: center;
    }

    .ant-collapse-arrow {
      color: $primary;
    }
  }
}

.Divider {
  margin: 15px 0;
  width: 100%;
}

.GridItem {
  box-shadow: none;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  padding: 0;
  width: 100%;
}

.Item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;

  &:first-child {
    padding-right: 5px;
  }

  &:last-of-type {
    padding-left: 5px;
  }

  & p {
    color: $stone-800;
    font-size: 11px;
    margin-bottom: 5px;
  }
}

.Tickets {
  align-items: center;
  display: flex;
  justify-content: left;

  & p {
    margin: 0 1em;
  }
}

.TicketValue {
  color: $stone-800;
  font-size: 15px !important;
}

.TicketButton {
  font-size: 20px;
  font-weight: 500;
  padding: 0 10px;
}

.SubTotalText {
  color: $stone-300;
  font-size: 11px;
  margin-bottom: -12px;
  margin-top: 15px;
}

.SubTotal {
  color: $stone-800;
}

.OversoldContainer {
  margin-top: 1rem;

  p {
    color: red;
  }
}

.ChildrenEnterForFree {
  font-size: 11px;
  margin-top: 10px;
}
