@import 'assets/globalStyles/variables';

.Title {
  color: $primary;
  line-height: 1.5;
  padding-top: 3vh;
  @media only screen and (min-width: $sm) {
    font-size: 31px;
  }
}

.SubTitle {
  color: $stone-400;
  font-size: medium;
  line-height: 1.5;
  padding-bottom: 2vh;
  @media only screen and (min-width: $sm) {
    font-size: 22px;
  }
}

.Row {
  justify-content: center;
  padding-top: 50px;
  @media only screen and (min-width: $sm) {
    background-color: $stone-50;
    justify-content: end;
  }
}

.ImageContainer {
  margin-top: -100px;
  @media only screen and (min-width: $sm) {
    height: 500px;
    margin-top: 0;
  }
}

.CardContainer {
  position: relative;
  z-index: 1;
}

.MainImage {
  background: linear-gradient(
    180deg,
    #000 0.08%,
    rgba(0, 0, 0, 0) 42.2%,
    rgba(34, 178, 103, 0.6) 99.92%
  );
  box-shadow: 4.95946px 7.43919px 12.39865px 0 rgba(0, 0, 0, 0.2);
  object-fit: cover;
  width: 100%;
  @media only screen and (min-width: $sm) {
    height: 100%;
  }
}

.MainText {
  color: white;
  font-size: x-large;
  font-weight: bold;
  line-height: 1.3;
  padding: 6vh 0 0 5vw;
  position: absolute;
  text-align: left;
  top: 8vh;
  @media only screen and (max-width: $md) {
    font-size: large;
    padding: 0;
  }
}

.Card {
  border-radius: 7px;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
  line-height: 1.3;
  padding: 45px 3vw;
  @media only screen and (max-width: $md) {
    padding: 30px 3vw;
  }
  @media only screen and (max-width: $sm) {
    line-height: inherit;
    padding: 3vh 4vw;
  }

  > div {
    padding: 0;
  }
}

.CardTitle {
  color: $secondary;
  font-size: xx-large;
  font-weight: bold;
  line-height: 1.2;
  @media only screen and (max-width: $md) {
    font-size: x-large;
    line-height: 1.4;
    margin-bottom: 0;
  }
  @media only screen and (max-width: $sm) {
    font-size: x-large;
    line-height: 1.4;
    padding-top: 10px;
  }
}

.CardSubtitle {
  font-size: large;
  line-height: 1.8;
  padding: 18px 0 0 52px;
  text-align: left;
  @media only screen and (max-width: $md) {
    font-size: medium;
    padding: 18px 0 0 18px;
  }
  @media only screen and (max-width: $sm) {
    font-size: small;
    line-height: inherit;
    padding: 18px;
    text-align: center;
  }
}

.NotAllowedImage {
  padding-top: 10px;
  width: 60%;
  @media only screen and (max-width: $md) {
    width: 80%;
  }
}
