@import 'assets/globalStyles/variables';

.Container {
  margin-bottom: 40px;
  margin-top: 40px;
  @media only screen and (max-width: $sm) {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.MobileContainer {
  overflow: hidden;
}

.Title {
  color: $primary;
  font-size: 34px;
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 1vh;
  @media only screen and (max-width: $sm) {
    padding: 1vh 3vw;
  }
}

.SubTitle {
  color: $primary;
  font-size: 32px;
  margin-bottom: 0;
  padding: 0 2%;
  text-align: left;
  @media only screen and (min-width: $sm) {
    margin-left: 8vw;
    margin-right: 8vw;
    padding: 0 1%;
  }
}

.ScrollEntrances {
  padding-top: 10px;
  text-align: left;
  @media only screen and (max-width: $md) {
    text-align: center;
  }
  @media only screen and (min-width: $sm) {
    margin-left: 8vw;
    margin-right: 8vw;
  }
}

.Column {
  display: inline-block;
  min-width: 310px;
  padding: 1.5vw 1vw;
  width: 100%;
  @media only screen and (max-width: $sm) {
    height: 100%;
    min-width: 360px;
    padding: 2vw 1vw;
    vertical-align: top;
    width: 80vw;
  }
}

.PayButton {
  margin-block: 48px;
  width: 100%;
}

.ButtonRow {
  @media only screen and (min-width: $sm) {
    margin-left: 8vw;
    margin-right: 8vw;
  }
}
