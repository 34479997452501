@import 'assets/globalStyles/variables';

.Container {
  padding: 40px 128px;
  @media (max-width: 768px) {
    padding: 40px;
  }
}

.Title {
  color: $fern-green-600;
  margin-bottom: 0;
}

.IconContainer {
  border-radius: 100%;
  height: 32px;
  padding: 2px;
  width: 32px;
}

.YellowContainer {
  @extend .IconContainer;
  background-color: $mandalay-500;
}

.GreenContainer {
  @extend .IconContainer;
  background-color: $fern-green-600;
}

.BlueContainer {
  @extend .IconContainer;
  background-color: $eastern-blue-600;
}

.BrownContainer {
  @extend .IconContainer;
  background-color: $brown;
}

.ScrollableList {
  margin-top: 40px;
  overflow: auto;
  white-space: nowrap;
}

.ScrollableImage {
  height: 284px;
  width: 20vw;
}
