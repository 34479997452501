@import 'assets/globalStyles/variables';

.Container {
  width: 100%;
}

.SecondaryButton {
  width: 100%;
}

.SocialMediaContainer {
  color: $stone-50;
}
