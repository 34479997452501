@import 'assets/globalStyles/variables';

.Container {
  background-color: $primary;
  border-radius: 10px;
  margin: 5vh 5vw;
  padding: 4vh 12vw;
  text-align: left;

  .Title {
    color: $stone-50;
    line-height: 1.2;
    @media only screen and (min-width: $sm) {
      padding-left: 28vw;
      padding-right: 28vw;
      text-align: center;
    }
    @media only screen and (min-width: $md) {
      padding-left: 10vw;
      padding-right: 10vw;
    }
  }

  .Text {
    color: $stone-50;
    line-height: 1.5;
    margin-bottom: 5vh;
    @media only screen and (min-width: $sm) {
      margin-bottom: 8vh;
      padding-left: 20vw;
      padding-right: 20vw;
    }
    @media only screen and (min-width: $md) {
      padding-left: 10vw;
      padding-right: 10vw;
    }
  }
  @media only screen and (min-width: $sm) {
    padding: 10vh 0 4vh;
  }
}

.MapCardsContainer {
  border-radius: 6px;
  display: flex;
  height: 80vh;
  padding: 2vh 4vw;
  width: 100%;
  @media only screen and (max-width: $sm) {
    display: block;
    height: 45vh;
    left: -17vw;
    position: relative;
    width: 100vw;
  }
}

.MapContainer {
  height: 100%;
  width: 100%;
  @media only screen and (max-width: $sm) {
    height: 50%;
    padding-left: 10vw;
    padding-right: 10vw;
  }
}

.Image {
  height: 80vh;
  width: 100vw;
  @media only screen and (max-width: $sm) {
    height: auto;
    object-fit: fill;
    width: 50%;
  }
}

.CardsContainer {
  bottom: 0;
  left: 0;
  overflow: scroll;
  padding-bottom: 6vh;
  position: absolute;
  width: 100vw;
  @media only screen and (min-width: $sm) {
    margin-top: -15px;
    padding-bottom: 0;
    position: relative;
  }
  @media only screen and (max-width: $sm) {
    padding-bottom: 0;
    padding-left: 10vw;
    padding-right: 10vw;
    position: relative;
  }
}
