/* stylelint-disable scss/at-extend-no-missing-placeholder */
/* stylelint-disable selector-class-pattern */
@import '../../../assets/globalStyles/variables';

$cover-image-height: 200px;
$actions-card-height: 160px;

.Container {
  background-color: $stone-50;
  border: unset;
  border: 1px solid $primary-700;
  border-radius: 10px;
  cursor: default;
  height: 100%;
  padding-bottom: 1vh;
  text-align: start;

  :global {
    .ant-card-cover {
      height: $cover-image-height;
    }

    .ant-card-body {
      padding-bottom: 48px;
    }

    /* stylelint-disable-next-line selector-max-compound-selectors */
    .ant-card-actions {
      background-color: $stone-100;
      border-radius: 0 0 10px 10px;
      border-top: unset;
      bottom: 1vh;
      padding-left: 24px;
      position: absolute;

      li::before {
        display: none;
      }

      li {
        text-align: start;
      }
    }

    .ant-card-meta-description {
      color: $primary;
      font-family: 'Barlow', sans-serif;
      font-size: 16px;
      letter-spacing: -0.24px;
    }

    .ant-card-meta-title {
      color: $primary;
      font-family: 'Barlow', sans-serif;
      font-size: 20px;
      font-weight: 700;
      letter-spacing: -0.36px;
      white-space: normal;
    }

    .ant-card-cover {
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;
    }
  }
}

.FixedContainer {
  @extend .Container;

  :global {
    .ant-card-body {
      height: 200px;
    }

    .ant-card-meta-title {
      box-orient: vertical;
      display: box;
      -webkit-line-clamp: 4;
      overflow: hidden;
    }
  }
}

.CardCoverImageContainer {
  display: inline-block;
  position: relative;
}

.CardCoverImageContainer::after {
  border-radius: 10px 10px 0 0 !important;
  content: '';
  display: inline-block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.GreenCardCoverImageContainer {
  @extend .CardCoverImageContainer;
}

.GreenCardCoverImageContainer::after {
  @extend .CardCoverImageContainer;
  background-image: linear-gradient(217deg, $primary 0%, rgba(34, 178, 103, 0) 30%);
}

.OrangeCardCoverImageContainer {
  @extend .CardCoverImageContainer;
}

.OrangeCardCoverImageContainer::after {
  @extend .CardCoverImageContainer;
  background-image: linear-gradient(217deg, $secondary 0%, rgba(255, 153, 0, 0) 30%);
}

.Featured {
  filter: brightness(0) invert(1);
  font-size: 25px;
  padding: 0.5vh;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9;
}

.Image {
  border-radius: 10px 10px 0 0 !important;
  display: block;
  height: $cover-image-height;
  object-fit: cover;
  width: 100%;
}

.Subtitle {
  color: $primary;
  margin-bottom: 5vh;
}

.ButtonContainer {
  bottom: 0;
  position: absolute;
}
