@import 'assets/globalStyles/variables';

.Container {
  margin: 3vh 0;
  width: 100%;
}

.Caption {
  color: $primary;
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
  line-height: 24px;
}

.ImagesContainer {
  margin-bottom: 3vh;

  :global {
    .slick-dots-bottom {
      bottom: 20px !important;
    }
  }
}

.ImageContainer {
  display: inline-block;
  pointer-events: none;
  position: relative;
  width: 100%;

  :global {
    .ant-image-mask {
      border-radius: 10px !important;
      pointer-events: all;
    }

    .ant-image {
      width: 100%;
    }
  }
}

.Image {
  border-radius: 10px !important;
  display: block;
  height: 250px;
  object-fit: cover;
  pointer-events: all;
  width: 100%;
}
