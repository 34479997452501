@import 'assets/globalStyles/variables';

.Title {
  color: $stone-800;
  font-size: xx-large;
  font-weight: bold;
}

.TotalContainer {
  padding-bottom: 1%;
  padding-top: 1%;

  p {
    margin-bottom: -10px;
  }
}

.TotalDetail {
  align-items: flex-end;
  display: flex;
  flex-direction: column;

  & h2 {
    color: $stone-800;
    margin: 0;
  }
}

.ContinueButton {
  min-width: 100px;
}

.SubTotal {
  color: $stone-800;
}

.AlertTooltip {
  z-index: 1 !important;
}
