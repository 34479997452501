@import 'assets/globalStyles/variables';

.Container {
  background-color: $mandalay-800;
  height: fit-content;
  padding: 8px 20px;
}

.TextContainer {
  width: 100%;
}

.NotificationContent {
  color: $mandalay-900;
  text-align: center;
}
