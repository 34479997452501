@import 'assets/globalStyles/variables';

.ContainerMobile {
  margin-top: 20px;
}

.Container {
  background-color: $stone-200;
  margin-bottom: 20px;
  padding-top: 20px;
}

.Text {
  color: $stone-600;
  font-size: 24px;
  font-weight: 300;
  line-height: 40px;
  text-align: start;

  b {
    color: $primary;
  }
}

.TextRight {
  /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
  @extend .Text;
  text-align: end;
}

.TextMobile {
  @extend .Text;
  margin-bottom: 5px;
}

@media screen and (max-width: $md) {
  .Text {
    text-align: center;
  }

  .TextRight {
    color: $primary;
  }
}
