@import 'assets/globalStyles/variables';

.Image {
  cursor: pointer;
  float: left;
  height: 14vw;
  object-fit: cover;
  padding: 2px;
  position: relative;
  width: 100%;
  @media only screen and (max-width: $sm) {
    height: 28vw;
  }
}

.Selected {
  &::after {
    background: $primary-800;
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    margin: 2px;
    opacity: 0.5;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.3s linear;
  }
}

.Video {
  background-color: transparent;
  cursor: pointer;
  height: 14vw;
  padding: 2px;
  width: 100%;
  @media only screen and (max-width: $sm) {
    height: 28vw;
  }
}
