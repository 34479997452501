@import 'assets/globalStyles/variables';

.Container {
  background-color: $brown;
  border-radius: 10px;
  justify-content: space-around;
  padding: 40px;
  @media only screen and (max-width: $sm) {
    padding: 20px;
  }
}

.ScrollableList {
  margin-top: 40px;
  overflow: auto;
  white-space: nowrap;
}

.ScrollableImage {
  height: 284px;
  width: 20vw;
}

.ImagesContainer {
  @media only screen and (max-width: $sm) {
    margin-top: 20px;
  }
}

.ImageContainer {
  padding: 0 10px;
  @media only screen and (max-width: $sm) {
    padding: 0;
  }
}

.ActivityImage {
  border-radius: 10px;
  height: 256px;
  max-width: 231px;
  width: 100%;

  @media only screen and (max-width: $sm) {
    max-width: unset;
  }
}

.Title {
  color: $stone-50 !important;
  margin-left: 8px;
  text-align: left;
}

.Description {
  color: $stone-50;
  margin-top: 12px;
  text-align: left;
}
