@import 'assets/globalStyles/variables';

.Container {
  @media only screen and (max-width: $sm) {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.Title {
  color: $stone-800;
  font-size: xx-large;
  font-weight: bold;
  @media only screen and (max-width: $sm) {
    font-size: x-large;
    margin-top: 1vh;
    padding: 0 3vw 1vh;
  }
}

.Date {
  color: $stone-800;
  @media only screen and (max-width: $sm) {
    font-size: large;
  }
}

.DateRow {
  @media only screen and (max-width: $md) {
    text-align: center;
  }
  @media only screen and (min-width: $sm) {
    margin-left: 8vw;
    margin-right: 8vw;
  }
}

.DateButtonCol {
  @media only screen and (max-width: $md) {
    padding-bottom: 16px;
  }
  @media only screen and (min-width: $md) {
    padding-left: 1vw;
    text-align: left;
  }
}

.ScrollEntrances {
  padding-top: 10px;
  text-align: left;
  @media only screen and (max-width: $md) {
    text-align: center;
  }
  @media only screen and (min-width: $sm) {
    margin-left: 8vw;
    margin-right: 8vw;
  }
}

.Column {
  display: inline-block;
  min-width: 310px;
  padding: 1.5vw 1vw;
  width: 100%;
  @media only screen and (max-width: $sm) {
    height: 100%;
    min-width: 360px;
    padding: 2vw 1vw;
    vertical-align: top;
    width: 80vw;
  }
}

.PayButton {
  margin-top: 3%;
  width: 100%;
  @media only screen and (max-width: $sm) {
    margin: 5% 0;
  }
}

.Spacer {
  height: 30px;
}

.Notification {
  background-color: #f5f5f5;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 20px;
  width: 25vw;
  @media only screen and (max-width: $sm) {
    width: 100vw;
  }
}
