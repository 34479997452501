@import 'assets/globalStyles/variables';

.GiftCardPreview {
  background-image: url('/assets/images/parrot-image.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 25px;
  box-shadow: 4.95946px 7.43919px 12.39865px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  width: 100%;
}

.Gradient {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.7));
  border-radius: 25px;
}

.GiftCardPreview::before {
  content: '';
  display: block;
  padding-top: 45%;
}

.GiftCardPreviewTextRow {
  padding-inline: 30px;
  text-align: start;
  @media only screen and (max-width: $sm) {
    padding-inline: 15px;
  }

  h2 {
    font-size: 24px;
    font-weight: normal;
    @media only screen and (max-width: $sm) {
      font-size: 18px;
      margin-bottom: 0;
    }
  }
}

.GiftCardPreviewPriceRow {
  padding: 0 30px 24px;
  @media only screen and (max-width: $sm) {
    padding: 0 15px 12px;
  }

  h2 {
    font-size: 32px;
    font-weight: normal;
    @media only screen and (max-width: $sm) {
      font-size: 22px;
    }
  }
}

.GiftCardPreviewName {
  text-align: start;
  width: 100%;
}

.GiftCardPreviewAmount {
  text-align: end;
  width: 100%;

  h2 {
    color: $stone-50;
  }
}

.NameField {
  color: $stone-50;
}
