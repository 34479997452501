@import './variables.scss';

// Input
.ant-input,
.ant-input:focus,
.ant-input-number,
.ant-input-number:focus,
.ant-input-affix-wrapper {
  background-color: $stone-50 !important;
  border: 1px solid $slate-200 !important;
  border-radius: 10px;
  font-family: Barlow;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding: 8px 12px !important;
  text-align: left;
}

.ant-input-number-affix-wrapper {
  background-color: $stone-50 !important;
  border: unset !important;
}

// Textarea

textarea.ant-input,
textarea.ant-input:focus {
  height: unset !important;
}

// radio button
.ant-radio-inner::after {
  background-color: $primary;
}

//Spin
.ant-spin {
  color: $primary;
}

// Collapse
.ant-collapse {
  background-color: $stone-50;
}

.ant-collapse-content {
  background-color: $stone-50;
}

// Card

.ant-card {
  background-color: $stone-50;
}

// Form {

.ant-form {
  background-color: $stone-50;
}

// Select {
.ant-select-selector {
  background-color: $stone-50 !important;
  border: 1px solid $slate-200 !important;
  border-radius: 6px !important;
  height: 40px !important;
  padding: 8px 12px !important;
}

.ant-select-selection-item {
  line-height: unset !important;
  text-align: left;
}

.ant-select-selection-placeholder {
  line-height: unset !important;
  text-align: left;
}

.ant-select-selection-overflow {
  flex-wrap: nowrap !important;
  overflow: scroll !important;
}

// DatePicker

.ant-picker {
  background-color: $stone-50 !important;
  border: 1px solid $slate-200 !important;
  border-radius: 6px !important;
  height: 40px !important;
  padding: 8px 12px !important;
}
