@import 'assets/globalStyles/variables';

.Container {
  height: 100%;
  position: relative;
  width: 100%;
  @media only screen and (min-width: $sm) {
    display: inline-block;
    vertical-align: top;
  }
}

.Card {
  border-radius: 15px;
  box-shadow: 10px 10px 15px 8px rgba(0, 0, 0, 0.2);
  min-height: auto;
  width: 100%;
}

.CardText {
  justify-content: center;
  margin-top: 15px;
  overflow: scroll;
  padding: 2% 6%;
  padding-top: 0;
  text-align: justify;

  :global {
    .ant-card-meta-title {
      background-color: white;
      overflow: hidden;
      white-space: pre-line;
      width: 90%;
      z-index: 3;

      h3 {
        box-orient: vertical;
        display: box;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
  }
}

.Description {
  @media only screen and (min-width: $sm) {
    height: 150px;
    overflow: scroll;
  }
}

.DescriptionSpace {
  box-shadow: none;
  justify-content: center;
  padding: 0 5% 5%;
  text-align: center;
  width: 100%;
}

.ShowMoreCollapse {
  :global {
    .ant-collapse-header {
      color: $primary !important;
      justify-content: center;
      padding-block: 6px !important;
      text-align: center;
    }

    .ant-collapse-arrow {
      color: $primary;
    }
  }
}

.CardImage {
  border-radius: 15px 15px 0 0 !important;
  height: 240px;
  object-fit: cover;
  width: 100%;
}

.BodyHorizontalLine {
  padding-left: -6%;
}

.HorizontalLine {
  margin-top: 0;
}

.PriceDetail {
  align-items: center;
  box-shadow: none;
  display: flex;
  flex-flow: column;
  padding: 2% 5%;

  & p {
    color: $stone-400;
    margin: 0;
  }
}

.Price {
  font-size: 32px;
  padding-block: 1vh;
}
