@import 'assets/globalStyles/variables';

.Container {
  margin-bottom: 148px;
  margin-top: 24px;
}

.FeedContainer {
  margin-top: 48px;
  justify-content: space-evenly;
  @media only screen and (max-width: $sm) {
    margin-top: 40px;
    padding-inline: 4vw;
  }
  @media only screen and (max-width: $md) {
    justify-content: center;
  }
}

.PostsContainer {
  padding: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  background-color: whitesmoke;
}

.MainText {
  color: $primary;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 24px;
  text-align: center;
  @media only screen and (max-width: $sm) {
    text-align: left;
  }
}

.SecondaryText {
  font-size: min(3vw, 18px);
  line-height: 2;
  text-align: center;
  @media only screen and (max-width: $sm) {
    text-align: left;
  }
}

.InstagramPost {
  display: block;
  visibility: visible;
  align-self: center;
  @media only screen and (max-width: $md) {
    visibility: collapse;
    display: none;
  }
}

.InstagramPostModalBody {
  :global {
    .ant-modal-body {
      visibility: hidden;
      padding: 0px;
      @media only screen and (max-width: $md) {
        display: block;
        visibility: visible;
        background-color: transparent;
        box-shadow: none;
      }
    }
  }
}

.InstagramPostModalWrap {
  :global {
    .ant-modal-mask {
      display: none;
      visibility: collapse;
    }
  }
}


