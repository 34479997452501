@import 'assets/globalStyles/variables';

.Card {
  border-radius: 10px;
  box-shadow: 0 3px 8px rgba(50, 50, 50, 0.17);
  margin-top: 2%;
  padding: 0 4%;
  text-align: left;
  @media only screen and (max-width: $sm) {
    margin-top: 30px;
  }
}

.Title {
  color: $stone-800;
  font-weight: bold;
}

.SubTotal {
  font-weight: bold;
}
