@import 'assets/globalStyles/variables';

.Container {
  background-color: $stone-200;
  padding: 8px 12px;
}

.Image {
  max-height: 40px;
}

.ParkRulesTitle {
  margin-top: 8px;
}
