@import '../../../assets/globalStyles/breakpoints.scss';
@import 'assets/globalStyles/variables';

.Container {
  margin-bottom: 20px;
  margin-top: 40px;
  padding-inline: 40px;
}

.Title {
  color: $primary;
  margin-bottom: 20px;
  text-align: center;
}

.InformationContainer {
  background-color: $fern-green-800;
  border-radius: 5px;
  padding: 12px 0;

  h6 {
    color: $stone-50;
  }

  h3 {
    color: $stone-50;
  }
}

.ScrollableList {
  margin-top: 40px;
  overflow: auto;
  white-space: nowrap;
}

.ScrollableImage {
  height: 284px;
  width: 20vw;
}
