@import '../../assets/globalStyles/variables';

.ImageContainer {
  height: 300vh;
  left: 0;
  position: absolute;
  top: 0;
}

.BackGroundImageContainer {
  display: inline-block;
  height: 100%;
  position: relative;
}

.BackGroundImageContainer::after {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.56) 0%, white 96.98%);
  border-radius: 10px 10px 0 0 !important;
  content: '';
  display: inline-block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.Image {
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.Title {
  color: $primary;
}
