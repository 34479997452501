@import 'assets/globalStyles/variables';

.Container {
  border-radius: 0 0 10px;
  justify-content: space-around;
  padding: 40px;
  @media only screen and (max-width: $sm) {
    padding: 20px;
  }
}

.YellowContainer {
  @extend .Container;
  background-color: $mandalay-500;
}

.GreenContainer {
  @extend .Container;
  background-color: $fern-green-600;
}

.BlueContainer {
  @extend .Container;
  background-color: $eastern-blue-600;
}

.BrownContainer {
  @extend .Container;
  background-color: $brown;
}

.ImagesContainer {
  @media only screen and (max-width: $sm) {
    margin-top: 20px;
  }
}

.ImageContainer {
  padding: 0 10px;
  @media only screen and (max-width: $sm) {
    padding: 0;
  }
}

.Icon {
  border-radius: 100%;
  height: 32px;
  padding: 2px;
  width: 32px;
}

.YellowIcon {
  @extend .Icon;
  background-color: $mandalay-600;
}

.GreenIcon {
  @extend .Icon;
  background-color: $fern-green-800;
}

.BlueIcon {
  @extend .Icon;
  background-color: $eastern-blue-800;
}

.BrownIcon {
  @extend .Icon;
  background-color: $brown;
}

.ActivityImage {
  border-radius: 10px;
  height: 256px;
  max-width: 231px;
  object-fit: cover;
  width: 100%;

  @media only screen and (max-width: $sm) {
    max-width: unset;
  }
}

.Title {
  color: $stone-50 !important;
  margin-left: 8px;
  text-align: left;
}

.Description {
  color: $stone-50;
  margin-top: 12px;
  text-align: left;
}
