@import 'assets/globalStyles/variables.scss';

.Title {
  color: $stone-800;
  @media only screen and (max-width: $sm) {
    margin: 8% 0;
  }
}

.Card {
  border-radius: 10px;
  box-shadow: 0 3px 8px rgba(50, 50, 50, 0.17);
  padding: 1% 3%;
}

.InputText {
  text-align: left;
}

.InputArrow {
  color: $primary;
}

.Footer {
  display: flex;
  justify-content: left;
  margin: 3% 0;
  text-align: left;
}

.Mail {
  color: $primary;
}
