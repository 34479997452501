@import 'assets/globalStyles/variables';

.Container {
  height: 150px;
  position: relative;
  width: 150px;
}

.TextContainer {
  bottom: 14px;
  left: 0;
  padding-inline: 8px;
  position: absolute;
  text-align: start;
  width: 100%;
  z-index: 1;
}

.TitleContainer {
  white-space: break-spaces;
}

.Title {
  color: $stone-50;
  max-width: 100px;
  overflow-wrap: break-word;
  word-break: break-word;
}

.Icon {
  height: 28px;
}

.ImageContainer {
  bottom: 0;
  left: 0;
  position: absolute;
}

.Gradient {
  border-radius: 10px;
  height: 150px;
  width: 150px;
  z-index: 1;
}

.GreenGradient {
  @extend .Gradient;
  background: linear-gradient(180deg, rgba(51, 75, 41, 0) 0%, rgba(51, 75, 41, 0.8) 100%);
}

.YellowGradient {
  @extend .Gradient;
  background: linear-gradient(
    180deg,
    rgba(203, 153, 39, 0) 0%,
    rgba(203, 153, 39, 0.7) 100%
  );
}

.BlueGradient {
  @extend .Gradient;
  background: linear-gradient(
    180deg,
    rgba(25, 100, 113, 0) 0%,
    rgba(25, 100, 113, 0.7) 100%
  );
}

.BrownGradient {
  @extend .Gradient;
  background: linear-gradient(180deg, rgba(90, 76, 57, 0) 0%, rgba(90, 76, 57, 0.8) 100%);
}

.MainImage {
  border-radius: 10px;
  height: 150px;
  object-fit: cover;
  width: 150px;
}
