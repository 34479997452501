@import 'assets/globalStyles/variables';

.Container {
  background-color: $stone-900;
  margin-top: -5%;
  padding: 5% 0;
  @media only screen and (max-width: $sm) {
    margin-top: 0;
  }
}

.Title {
  color: $primary;
  text-align: left;
}

.Description {
  color: $stone-400;
  text-align: left;
}

.ScrollableList {
  justify-content: space-between;
  margin: 2% 0 1% 5%;
  overflow: auto;
  white-space: nowrap;
  @media only screen and (max-width: $sm) {
    display: flex;
    margin: 3% 0 3% 5%;
  }
}

.UnselectedButton {
  background: $stone-800;
  border: $stone-800;
  border-radius: 5px;
  font-size: large;
  font-weight: bold;
  height: 6em;
  margin-right: 2%;
  padding-top: 2%;
  white-space: initial;
  width: 8em;

  p {
    margin-bottom: 0 !important;
    overflow-wrap: break-word;
  }
  @media only screen and (max-width: $md) {
    font-size: 100%;
    white-space: normal;

    p {
      margin-bottom: 0 !important;
      overflow-wrap: break-word;
    }
  }
}

.UnselectedButton:hover {
  background: $primary;
  color: $stone-400 !important;
}

.SelectedButton:hover {
  background: $primary;
  color: $stone-400 !important;
}

.SelectedButton:focus {
  background: $primary;
  color: $stone-400 !important;
}

.SelectedButton {
  border: $primary;
  border-radius: 5px;
  font-size: large;
  font-weight: bold;
  height: 6em;
  margin-right: 2%;
  padding-top: 2%;
  white-space: initial;
  width: 8em;

  p {
    margin-bottom: 0 !important;
    overflow-wrap: break-word;
  }
  @media only screen and (max-width: $md) {
    font-size: 100%;
    white-space: normal;

    p {
      margin-bottom: 0 !important;
      overflow-wrap: break-word;
    }
  }
}

.ImagesContainer {
  height: 25em;
  overflow: auto;
  white-space: nowrap;
  @media only screen and (max-width: $lg) {
    height: 100%;
    width: 100%;
  }
}

.ActivityImage {
  background: $primary;
  display: inline-block;
  height: 100%;
  margin-right: 2%;
  width: 33em;

  & img {
    border-radius: 5px;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    width: 100%;
  }
  @media only screen and (max-width: $lg) {
    height: 60vw;
    width: 65%;
  }
}

.GrillDescription {
  color: $stone-400;
  text-align: left;
}
