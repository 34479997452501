@import '../../../assets/globalStyles/variables';

.TitleBlock {
  margin-top: -3.5em;
  @media only screen and (min-width: $lg) {
    position: absolute;
    width: 45%;
  }
}

.Block {
  background-color: $stone-800;
  padding: 12% 0;
  @media only screen and (min-width: $lg) {
    border-radius: 2px;
    padding: 15% 0;
  }
}

.LetterBlock {
  display: flex;
  justify-content: center;
  @media only screen and (min-width: $lg) {
    width: 100%;
  }
}

.LetterSquare {
  background-color: $secondary;
  color: $stone-400;
  font-size: xxx-large;
  margin: 0 0 -0.6em;
  position: relative;
  width: 2em;
}

.GrillImage {
  height: 92vh;
  object-fit: cover;
  width: 100%;
  @media only screen and (max-width: $lg) {
    height: 55vh;
  }
}

.Title {
  font-size: 3em;
  font-weight: bold;
}

.Description {
  color: $primary;
  font-weight: bold;
  margin-top: 15%;
  text-align: left;
  @media only screen and (max-width: $sm) {
    font-size: medium;
  }
}

.FlowersImage {
  margin-bottom: -$main-padding-bottom;
  margin-right: -$main-padding-right;
  width: stretch;
}
