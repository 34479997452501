@import 'assets/globalStyles/variables';

.Container {
  margin-left: -$main-padding-right;
  margin-right: -$main-padding-right;
  padding: 3% 6%;

  h2 {
    color: $fern-green-600;
  }
}

.ScrollableList {
  margin: 40px 0;
  overflow: auto;
  white-space: nowrap;
}

.ScrollableImage {
  height: 284px;
  width: 20vw;
}

.ListGrillsTitle {
  font-size: 30px;
  line-height: 1.2;
  padding-bottom: 3vh;
  @media only screen and (max-width: $sm) {
    font-size: 22px;
    padding-bottom: 2vh;
    text-align: left;
  }
}

.UnselectedButton {
  background: $stone-800;
  border: $stone-800;
  border-radius: 5px;
  color: $stone-400;
  font-size: large;
  font-weight: bold;
  height: 120px;
  margin: 0 1%;
  max-width: 130px;
  min-width: 130px;
  padding-top: 1%;
  text-align: -webkit-center;
  vertical-align: middle;
  white-space: normal;
  width: 130px;

  p {
    margin-bottom: 0 !important;
    overflow-wrap: break-word;
  }
  @media only screen and (max-width: $md) {
    font-size: 100%;
    font-weight: normal;
    height: 100px;
    max-width: 100px;
    min-width: 100px;
    width: 100px;

    p {
      margin-bottom: 0 !important;
      overflow-wrap: break-word;
    }
  }
}

.UnselectedButton:hover {
  background: $primary;
  color: $stone-400 !important;
}

.SelectedButton:hover {
  background: $primary !important;
  color: $stone-400 !important;
}

.SelectedButton:focus {
  background: $primary !important;
  color: $stone-400 !important;
}

.SelectedButton {
  background: $primary;
  border: $primary;
  border-radius: 5px;
  color: $stone-400;
  font-size: large;
  font-weight: bold;
  height: 120px;
  margin: 0 1%;
  max-width: 130px;
  min-width: 130px;
  padding-top: 1%;
  text-align: -webkit-center;
  vertical-align: middle;
  white-space: normal;
  width: 130px;

  p {
    margin-bottom: 0 !important;
    overflow-wrap: break-word;
  }
  @media only screen and (max-width: $md) {
    font-size: medium;
    font-weight: normal;
    height: 100px;
    max-width: 100px;
    min-width: 100px;
    width: 100px;

    p {
      margin-bottom: 0 !important;
      overflow-wrap: break-word;
    }
  }
}
