/* stylelint-disable property-no-unknown */
/* stylelint-disable scss/at-extend-no-missing-placeholder */
@import 'assets/globalStyles/variables';

.Container {
  height: 480px;
  left: 0;
  position: relative;
}

.ContainerMobile {
  height: 440px;
}

.LeftIconContainer {
  color: white;
  text-align: end;
}

.LeftIcon {
  cursor: pointer;
  font-size: 30px;
  margin-top: 40px;
}

.ImageContainer {
  background-color: $stone-100;
  height: 480px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
}

.ImageContainerMobile {
  @extend .ImageContainer;
  height: 440px;
}

.ImageBlackContainer {
  display: inline-block;
  position: relative;
}

.ImageBlackContainer::after {
  background: linear-gradient(
    205deg,
    $primary 0%,
    rgba(38, 48, 43, 0.6) 17.99%,
    rgba(38, 48, 43, 0.8)
  );
  content: '';
  display: inline-block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.Image {
  display: block;
  height: 480px;
  object-fit: cover;
  object-position: top left;
  width: 100vw;
}

.ImageMobile {
  @extend .Image;
  height: 440px;
  object-fit: cover;
  width: 100vw;
}

.ProgramText {
  color: $stone-50;
  text-align: start;
}

.Title {
  box-orient: vertical;
  color: $stone-50;
  display: box;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-align: start;
  @media only screen and (max-width: $sm) {
    font-size: 26px;
    -webkit-line-clamp: 6;
  }
}
