@import 'assets/globalStyles/variables';

.Container {
  padding: 40px 128px;
  @media only screen and (max-width: $lg) {
    padding: 40px;
  }
}

.TitleContainer {
  margin-left: 15vw;
  margin-top: 15vw;
  width: 50vw;
  @media only screen and (min-width: $sm) {
    margin-left: 0;
    margin-top: 5vh;
    text-align: center;
    width: 100%;
  }
}

.Title {
  color: $fern-green-600;
}

.Icon {
  width: 8vw;
  @media only screen and (min-width: $sm) {
    width: 35px;
  }
}

.Text {
  color: white;
  font-size: min(3.5vw, 18px);
  line-height: 2;
  padding-left: 15vw;
  padding-right: 15vw;
  text-align: left;
  @media only screen and (min-width: $sm) {
    font-size: min(3.5vw, 16px);
    padding-left: 15vw;
    padding-right: 15vw;
  }
}
