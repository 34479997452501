@import 'assets/globalStyles/variables';

.SeeMoreButton {
  background-color: white;
  border-radius: 100px;
  margin-bottom: 3vh;
}

.InformationCardColumn {
  margin-bottom: 5vh;
}
