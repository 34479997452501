@import 'assets/globalStyles/variables';

.Container {
  @media only screen and (max-width: $sm) {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.Title {
  color: $stone-800;
  font-size: xx-large;
  font-weight: bold;
  @media only screen and (max-width: $sm) {
    font-size: x-large;
    margin-top: 1vh;
    padding: 0 3vw 1vh;
  }
}

.Date {
  color: $stone-800;
  @media only screen and (max-width: $sm) {
    font-size: large;
  }
}

.AddAnotherEntrance {
  margin-top: 24px;

  p {
    margin-top: 6px;
  }
}

.PayButton {
  float: right;
  margin: 1% 3%;
  width: 20%;
  @media only screen and (max-width: $sm) {
    margin: 5% 3%;
    width: 90%;
  }
}

.TotalDetail {
  color: $stone-800;
  margin-bottom: 0;
  padding: 1% 3% 0;
  text-align: right;
}

.DiscountText {
  color: $stone-400;
  margin-bottom: 0;
  padding: 0 3%;
  text-align: right;
}

.TotalBar {
  background-color: $stone-50;
}

.warningMessage {
  color: red;
  font-size: 12px;
  margin: 0;
  text-align: left;
}

.NotificationContent {
  h1 {
    color: #21b367 !important;
    font-size: 40px;
    font-weight: 700;
    line-height: 40px;
    @media only screen and (max-width: $sm) {
      font-size: 30px;
      line-height: 30px;
    }
  }

  h2 {
    color: white;
    font-size: 24px;
    font-weight: 700;
    line-height: 25px;
  }

  h3 {
    color: #1e2027;
    font-size: 24px;
    font-weight: 500;
    line-height: 25px;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    @media only screen and (max-width: $sm) {
      font-size: 13px;
      line-height: 160%;
    }
  }

  a {
    color: $stone-400;
  }
}
