@import '../../assets/globalStyles/variables';

.Container {
  margin-right: -$main-padding-right;
  margin-top: -$main-padding-top;
}

.PlaceContainer {
  margin-bottom: 20px;
}

.Space {
  height: 3vh;
}
