@import 'assets/globalStyles/variables';

.OpenDayContainer {
  display: grid;
  justify-content: center;
  width: 100%;
}

.OpenDayPickerHeader {
  color: #21b367;
  font-size: 30px;
  padding-left: 11px;
}

.OpenDayPicker {
  border-radius: 12px;
  justify-items: center;
  margin: 20px;
  max-width: 33vw;
  padding: 10px;
  @media (max-width: $md) {
    max-width: none !important;
  }

  :global {
    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
      background-color: $primary;
      border-radius: 50%;
      color: $stone-50 !important;
    }

    .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner {
      color: $primary;
    }

    .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
      border-color: $primary;
      border-radius: 50%;
    }

    .ant-picker-cell-disabled {
      background-color: transparent;
      color: rgba(0, 0, 0, 0.25) !important;
      pointer-events: all;
    }

    .ant-picker-cell-disabled .closed-day {
      background-color: transparent;
      color: $secondary !important;
      pointer-events: all;
    }

    .ant-picker-cell-disabled::before {
      background-color: transparent;
    }

    .ant-picker-cell {
      color: $primary;
    }
  }
}

.OpenDayPickerYear {
  color: $primary;
  padding-inline: 11px;
}

.OpenDayPickerMonthSelect {
  :global {
    .ant-select-selection-item {
      color: $primary;
      font-size: 30px;
      padding-right: 0 !important;
    }

    .ant-select-selector {
      padding: unset !important;
    }
  }
}

.OpenDayPickerYearSelect {
  :global {
    .ant-select-selection-item {
      color: $primary;
      padding: 0 !important;
    }

    .ant-select-selector {
      padding: 0 13px !important;
    }

    .ant-select-arrow {
      display: none;
    }
  }
}

.OpenDayPickerButton {
  margin-bottom: 2vh;
  margin-inline: 5vw;
}

.MonthCounterButton {
  cursor: default;
}

.MonthCounterIcon {
  color: $primary;
  cursor: pointer;
  font-size: 40px;

  :hover {
    color: $primary-800;
  }
}

.DisabledMonthCounterIcon {
  font-size: 40px;
  visibility: hidden;
}

.ImportantInformationModal {
  :global {
    .ant-modal-title {
      color: $primary;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
    }
  }

  p {
    b {
      color: $secondary;
    }
  }
}
