@import 'assets/globalStyles/variables';

.Form {
  background: $stone-100;
  border-radius: 5px;
  padding: 8px 12px;
  text-align: center;
  width: 100%;
  @media only screen and (max-width: $sm) {
    padding: 20px;
  }

  form {
    background: $stone-100 !important;
  }
}

.FormTitle {
  color: $primary;
  font-size: x-large;
  font-weight: bold;
  line-height: unset;
  margin-bottom: 6px;
}

.FormAttentionSchedule {
  color: $primary;
  font-size: 12px;
  font-weight: bold;
}

.Input {
  border-color: $stone-200;
}

.FormButton {
  width: 100%;
}

.FormItem {
  margin-bottom: 12px;
}

.formLabel {
  color: $primary;
  margin-bottom: 4px;
  text-align: left;
}
