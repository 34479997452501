@import 'assets/globalStyles/variables';

.container {
  text-align: left;
  width: 100%;
}

.collapse {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.panel {
  border-radius: 10px !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
  margin-bottom: 10px;
  padding: 0 20px;

  a {
    color: $eastern-blue-300;
  }

  a:hover {
    color: $eastern-blue-800;
  }
}

.panelText {
  font-size: 15px;
}
