/* stylelint-disable-next-line scss/at-import-partial-extension-blacklist */
@import 'assets/globalStyles/variables.scss';

.Card {
  border-radius: 10px;
  box-shadow: 0 3px 8px rgba(50, 50, 50, 0.17);
  margin-top: 2%;
  padding: 0 4%;
  text-align: left;

  :global {
    /* stylelint-disable-next-line selector-class-pattern */
    .ant-card-head-title {
      padding: 16px 0 0;
    }
  }
}

.SelectedCard {
  @extend .Card;
  border: 2px solid $primary !important;
}

.ItemName {
  color: $stone-800;
  font-weight: bold;
}

.SubTotal {
  font-weight: bold;
}

.BlackTextColor {
  color: black;
}
