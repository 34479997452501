@import 'assets/globalStyles/variables';

.CardContainer {
  margin-left: 48px;
  width: auto;
  @media only screen and (max-width: $lg) {
    margin-bottom: 48px;
    margin-left: 0;
    margin-top: 14px;
  }
}

.Card {
  border-radius: 7px;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
  line-height: 1.3;
  padding: 45px 3vw 40px;
  text-align: start;
  @media only screen and (max-width: $md) {
    padding: 30px 3vw;
  }
  @media only screen and (max-width: $sm) {
    line-height: inherit;
    padding: 3vh 4vw;
  }

  > div {
    padding: 0;
  }
}

.CardTitle {
  color: $primary;
  font-weight: bold;
  padding-bottom: 24px;
}

.FormTitle {
  color: $primary;
  font-size: xx-large;
  font-weight: bold;
  @media only screen and (max-width: $sm) {
    font-size: x-large;
  }
}

.Input {
  width: 100%;
}

.FormLabel {
  color: $primary;
  margin-bottom: 12px;
  text-align: left;
}

.FormButton {
  align-items: center;
  height: 100%;
  width: 100%;
}

.FormButton:hover {
  background-color: $secondary !important;
  color: $stone-400 !important;
}
