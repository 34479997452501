@import 'assets/globalStyles/variables';

.Container {
  padding-bottom: 96px;
  padding-top: 96px;
  text-align: center;
  width: 100%;
  @media only screen and (max-width: $md) {
    padding-bottom: 0;
    padding-top: 0;
  }
}

.TexturedContainer {
  background-image: url('/assets/images/welcomeDroBackground.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 444px;
  @media only screen and (max-width: $md) {
    height: auto;
  }
}

.FullWidth {
  width: 100%;
}

.Image {
  border-top-right-radius: 24px;
  height: 508px;
  margin-top: -32px;
  object-fit: cover;
  padding-left: 64px;
  width: 100%;

  @media only screen and (max-width: $md) {
    height: 350px;
    margin-top: 32px;
    padding-left: 0;
  }
}

.TextContainer {
  padding: 64px;
  @media only screen and (max-width: $md) {
    padding: 20px 0 32px;
  }
}

.Title {
  color: $stone-50 !important;
  text-align: start;
  width: 100%;
}

.Paragraph {
  color: $stone-50 !important;
  margin-top: 20px;
  text-align: start;
  width: 100%;
}

.AboutUsButton {
  margin-top: 20px;
  width: 100%;
}
