@import 'assets/globalStyles/variables';

.Container {
  margin-left: -$main-padding-right;
  margin-right: -$main-padding-right;
  padding: 3% 6%;

  h2 {
    color: $fern-green-600;
  }
}

.ScrollableList {
  cursor: pointer;
  margin: 2% 0;
  overflow-x: scroll;
  overflow-y: hidden;
  transition: all 0.2s;
  user-select: none;
  white-space: nowrap;
  @media only screen and (max-width: $sm) {
    display: block;
    margin: 3% 0 3% 5%;
  }
}

.Header {
  color: $stone-50;
  font-size: xx-large;
  line-height: 1.2;
  padding-bottom: 4%;
  padding-top: 8vh;
  @media only screen and (max-width: $sm) {
    font-size: x-large;
    padding-bottom: 6%;
    padding-top: 3vh;
    text-align: left;
  }
}

.SubTitle {
  color: $stone-50;
  font-size: medium;
  line-height: 1.5;
  padding-bottom: 8vh;
  @media only screen and (max-width: $sm) {
    font-size: small;
    font-weight: normal;
    padding-bottom: 18px;
    padding-bottom: 25px;
    text-align: left;
  }
}

.ActivitiesTitle {
  color: $stone-50;
  font-size: 30px;
  line-height: 1.2;
  padding-bottom: 3vh;
  @media only screen and (max-width: $sm) {
    font-size: 22px;
    padding-bottom: 2vh;
    text-align: left;
  }
}

.UnselectedButton {
  background: $primary-800;
  border: $primary-800;
  border-radius: 12px;
  color: $stone-50;
  font-size: large;
  font-weight: bold;
  height: 160px;
  margin: 0 1%;
  max-width: 150px;
  min-width: 130px;
  padding-top: 1%;
  text-align: -webkit-center;
  vertical-align: middle;
  white-space: normal;

  p {
    margin-bottom: 0 !important;
    overflow-wrap: break-word;
  }
  @media only screen and (max-width: $md) {
    font-size: 100%;
    font-weight: normal;
    height: 115px;
    max-width: 115px;
    min-width: 100px;

    p {
      font-size: 11px;
      margin-bottom: 0 !important;
      overflow-wrap: break-word;
    }
  }
}

.UnselectedButton:hover {
  background: $primary-700;
  color: $stone-50 !important;
}

.SelectedButton:hover {
  background: $primary-700 !important;
  color: $stone-50 !important;
}

.SelectedButton:focus {
  background: $primary-700 !important;
  color: $stone-50 !important;
}

.SelectedButton {
  background: $primary-700;
  border: $primary-800;
  border-radius: 12px;
  color: $stone-50;
  font-size: large;
  font-weight: bold;
  height: 160px;
  margin: 0 1%;
  max-width: 150px;
  min-width: 130px;
  padding-top: 1%;
  text-align: -webkit-center;
  vertical-align: middle;
  white-space: normal;

  p {
    margin-bottom: 0 !important;
    overflow-wrap: break-word;
  }
  @media only screen and (max-width: $md) {
    font-size: medium;
    font-weight: normal;
    height: 115px;
    max-width: 115px;
    min-width: 100px;

    p {
      font-size: 11px;
      margin-bottom: 0 !important;
      overflow-wrap: break-word;
    }
  }
}
