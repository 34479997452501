@import 'assets/globalStyles/variables';

.Container {
  background-color: $stone-900;
  height: 460px;
  padding-left: 40px;
  position: relative;
}

.UpperText {
  color: $stone-500;
}

.Title {
  color: $primary;
  margin-top: 8px;
}

.MainTextContainer {
  height: 100%;
  padding: 64px 40px 0 0;
  text-align: start;
  @media only screen and (max-width: $sm) {
    background-color: rgb($stone-900, 0.9);
    left: 0;
    padding-inline: 40px;
    position: absolute;
    top: 0;
    z-index: 1;
  }
}

.Description {
  color: $stone-50;
  margin-top: 20px;
}

.QuoteButton {
  margin-top: 20px;
  width: 100%;
}

.MainImageContainer {
  @media only screen and (max-width: $sm) {
    left: 0;
    position: absolute;
    top: 0;
  }
}

.MainImage {
  border-radius: 24px 0 0 24px;
  height: 460px;
  object-fit: cover;
  width: 100%;
  @media only screen and (max-width: $sm) {
    border-radius: 0;
    width: unset;
  }
}
