@import 'assets/globalStyles/variables';

.Primary {
  :global {
    .ant-btn {
      background: $secondary;
      border-color: $secondary;
      border-radius: 6px;
      color: $stone-50;
    }

    .ant-btn-circle {
      border-radius: 50% !important;
    }

    .ant-btn-ghost {
      background: transparent;
      border-color: transparent;
      color: $mandalay-900;
    }

    .ant-btn:hover {
      background: $secondary-600;
      border-color: $secondary-600;
      color: $stone-50;
    }

    .ant-btn-ghost:hover {
      background: rgb($stone-50, 0.2);
      border-color: $mandalay-900;
      color: $mandalay-900;
    }

    .ant-btn-link,
    .ant-btn-text {
      background: transparent;
      border-color: transparent;
      color: $primary-800;

      &:hover {
        background: transparent;
        border-color: transparent;
        color: $primary;
      }
    }

    .ant-btn[disabled] {
      background: $stone-200;
      border-color: $stone-200;
      color: $stone-400;
    }

    .ant-card-meta-detail > div:not(:last-child) {
      margin-bottom: 0;
    }
  }
}

.Secondary {
  :global {
    .ant-btn {
      background-color: $stone-50;
      border-color: $secondary;
      border-radius: 6px;
      border-width: 2px;
      color: $secondary;
    }

    .ant-btn-circle {
      border-radius: 50% !important;
    }

    .ant-btn:hover {
      background-color: $secondary-50;
      border-color: $secondary-600;
      color: $secondary-600;
    }

    .ant-btn[disabled] {
      background: $stone-200;
      border-color: $stone-200;
      color: $stone-400;
    }
  }
}

.Ghost {
  :global {
    .ant-btn {
      background-color: #fafaf91a;
      border: 1px solid $slate-200;
      border-color: $slate-200;
      border-radius: 6px;
      color: $stone-50;
    }

    .ant-btn:hover {
      border-color: $primary;
      color: $primary;
    }
  }
}

.Selected {
  @extend .Primary;

  :global {
    .ant-btn {
      border-color: $primary-800;
      border-radius: 6px;
    }

    .ant-btn-circle {
      border-radius: 50% !important;
    }

    .ant-btn:hover {
      background: $primary-800;
      border-color: $primary-800;
      color: $stone-50;
    }
  }

  &ActivityTime {
    @extend .Selected;
    width: 100%;

    :global {
      .ant-btn {
        width: 90%;
      }
    }
  }
}
