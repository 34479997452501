@import '../../../assets/globalStyles/variables';

.stepsWrapper {
  padding-bottom: 25px;
  padding-top: 10px;
  @media only screen and (max-width: $sm) {
    margin-left: -11px;
    padding-top: 30px;
  }

  .ant-steps {
    justify-content: space-between;
  }

  .ant-steps-label-vertical .ant-steps-item-content {
    width: auto;
  }

  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: $primary !important;
    height: 3px;
  }

  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after,
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: $stone-200 !important;
    height: 3px;
  }

  .ant-steps-label-vertical .ant-steps-item-tail {
    z-index: 1 !important;
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    --antd-wave-shadow-color: white;
    background: $primary;
    background-color: $primary;
    border-color: $primary;
    color: white;
    margin-right: 0 !important;
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    background: $primary !important;
  }

  .ant-steps-item-process .ant-steps-item-icon {
    border-color: $primary !important;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: $primary !important;
  }

  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: white;
  }

  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: $stone-200;
    font-size: 21px;
    line-height: 27px;
    vertical-align: top;
  }

  .ant-steps-item-wait .ant-steps-item-icon {
    border: 3px solid $stone-200;
  }

  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-icon
    .ant-steps-icon {
    font-size: 21px;
    z-index: 2;
  }
}
