.Affix {
  margin-top: 10px;
  background-color: white;
  :global {
    .ant-affix {
      box-shadow: 0px -4px 4px 0px rgb(50 50 50 / 17%);
      background-color: white;
      width: 100%;
    }
  }
}