@import 'assets/globalStyles/variables';

.Container {
  min-height: 380px;
  padding: 40px 128px;

  @media only screen and (max-width: $lg) {
    height: 700px;
    padding: 20px;
  }
}

.InformationContainer {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
  padding: 0 20px 20px;
  width: 100%;

  @media only screen and (max-width: $lg) {
    border-radius: 12px !important;
  }
}

.InformationContainerLeft {
  @extend .InformationContainer;
  border-radius: 12px 0 0 12px;
}

.InformationContainerRight {
  @extend .InformationContainer;
  border-radius: 0 12px 12px 0;
}

.TextContainer {
  height: 100%;
}

.Text {
  color: $stone-50;
  text-align: left;
}

.Gradient {
  background: linear-gradient(180deg, rgba(28, 25, 23, 0) 0%, #1c1917 100%);
  height: 100%;
  width: 100%;
}
