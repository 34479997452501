@import 'assets/globalStyles/variables';

.LayoutHeader {
  background-color: $primary;
  box-shadow: 0 2px 3px rgb(0 0 0 / 8%);
  height: max-content;
  margin: auto;
  padding: 0 !important;
  width: 100%;
}

.MainContainer {
  background-color: $stone-100;
  min-height: calc(100vh - $navbar-height);
  overflow-x: hidden;
  overflow-y: hidden;
  padding-bottom: $main-padding-bottom;
  text-align: center;

  &::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (max-width: $sm) {
    padding: 0;
  }
}

.LayoutFooter {
  background-color: $primary;
  padding: 0;
  z-index: 2;
}

.ComponentContainer {
  height: 100%;
  width: 100%;
}
