@import 'assets/globalStyles/variables';

.Container {
  margin-block: 32px;
}

.ActualParkAvailability {
  border: 2px solid $primary;
  padding: 12px;
  border-radius: 20px;
  font-size: x-large;
  @media only screen and (max-width: $sm) {
    font-size: large;
  }
}

.NotActualParkAvailability {
  padding: 12px;
  border-radius: 10%;
  font-size: x-large;
  opacity: 0.5;
  @media only screen and (max-width: $sm) {
    font-size: large;
  }
}