.MainContainer {
  background-image: url('/assets/images/share-links-background-image.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: calc(100vh - 64px);
  text-align: center;
  padding-top: 32px;
  padding-bottom: 20px;
}
