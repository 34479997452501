@import 'assets/globalStyles/variables';

.Container {
  margin-top: 40px;
}

.YearContainer {
  max-height: 432px;
  overflow-y: scroll;

  @media only screen and (max-width: $sm) {
    max-height: 150px;
  }
}

.HeaderContainer {
  background-color: $stone-50;
  cursor: pointer;
  padding-left: 20px;
  padding-top: 20px;
}

.SelectedHeaderContainer {
  @extend .HeaderContainer;
  cursor: default;
}

.Header {
  text-align: left;

  h4 {
    color: $stone-900;
  }

  p {
    color: $stone-900;
  }
}

.SelectedHeader {
  @extend .Header;

  h4 {
    color: $fern-green-600;
  }

  p {
    color: $fern-green-600;
  }
}

.Divider {
  color: $stone-300;
  margin: 10px 0;
}

.TimeLineDetailDivider {
  border-top: 1px solid $stone-50;
  color: $stone-50;
  margin: 10px 0;
}

.DescriptionContainer {
  height: 432px;
  position: relative;
  width: 100%;
}

.Gradient {
}

.ImageContainer {
  height: 432px;
  left: 0;
  position: absolute;
  position: relative;
  top: 0;
  width: 100%;
}

.Image {
  border-radius: 0 20px 20px 0;
  height: 432px;
  object-fit: cover;
  width: 100%;
  @media only screen and (max-width: $sm) {
    border-radius: 20px;
  }
}

.ImageContainer::after {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  border-radius: 0 0 20px;
  bottom: 0;
  content: '';
  height: 50%;
  left: 0;
  position: absolute;
  width: 100%;

  @media only screen and (max-width: $sm) {
    border-radius: 0 0 20px 20px;
  }
}

.TextContainer {
  bottom: 20px;
  left: 20px;
  max-height: 432px;
  overflow-y: scroll;
  padding-right: 20px;
  position: absolute;
  text-align: left;
}

.Text {
  color: $stone-50;
  margin-bottom: 0 !important;
}
