@import 'assets/globalStyles/variables';

.TextContainer {
  text-align: justify;

  h1 {
    color: $primary !important;
  }

  h2,
  h4 {
    color: white !important;
  }

  h3 {
    color: black !important;
  }

  p {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 32px !important;
  }
}

.Container {
  margin: 5vh 0;
  width: 100%;
  pointer-events: none;

  :global {
    .ant-image-mask {
      pointer-events: all;
      border-radius: 10px !important;
    }
    
    .ant-image {
      width: 100%;
    }
  }
}

.Caption {
  color: $primary;
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
  line-height: 24px;
}

.ImageContainer {
  display: inline-block;
  position: relative;
  width: 100%;
}

.ImageContainer::after {
  background-image: linear-gradient(217deg, $primary 0%, rgba(34, 178, 103, 0) 50%);
  border-radius: 10px !important;
  content: '';
  display: inline-block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.Image {
  border-radius: 10px !important;
  display: block;
  height: 400px;
  object-fit: cover;
  width: 100%;
  pointer-events: all;
}

.ImageMobile {
  @extend .Image;
  height: 30vh;
}