@import 'assets/globalStyles/variables';

.Card {
  border-radius: 10px;
  box-shadow: 0 3px 8px rgba(50, 50, 50, 0.17);
  margin-top: 2%;
  padding: 4% 6%;
  text-align: justify;

  > div {
    padding: 0;
  }
  @media only screen and (max-width: $md) {
    margin-top: 5%;
  }
}

.Title {
  font-size: large;
  font-weight: bold;
  margin-bottom: 25px;
  @media only screen and (max-width: $md) {
    margin-bottom: 20px;
  }
}

.GridItem {
  box-shadow: none;
  display: flex;
  justify-content: center;
  padding: 3% 0 0;
}

.ActivityTimesTitle {
  color: $stone-800;
  margin-bottom: 0;
  @media only screen and (max-width: $sm) {
    font-size: 20px;
    margin-bottom: 10px;
  }
}

.OversoldContainer {
  margin-top: 1rem;

  p {
    color: red;
  }
}

.NoRemaining {
  color: $secondary;
}

.LargeButton {
  height: 50px;

  p {
    margin-bottom: 0;
  }
}
