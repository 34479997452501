@import 'assets/globalStyles/variables';

.Container {
  background-color: $primary-800;
  margin-left: -$main-padding-right;
  margin-right: -$main-padding-right;
  padding-bottom: 6%;
  padding-top: 6vh;
  @media only screen and (max-width: $md) {
    margin-top: -1.6vw;
    padding-top: 15px;
  }
}

.TitleContainer {
  padding: 0;
  @media only screen and (max-width: $sm) {
    padding: 5%;
  }
}

.Title {
  color: $stone-50;
  font-size: xx-large;
  font-weight: bold;
  line-height: 1.2;
  padding-bottom: 5vh;
  @media only screen and (max-width: $sm) {
    font-size: x-large;
    padding-bottom: 0;
    text-align: left;
  }
}

.SubTitle {
  color: $stone-50;
  display: none;
  font-size: medium;
  text-align: left;
  @media only screen and (max-width: $sm) {
    display: block;
    font-size: small;
  }
}

.Dots {
  height: 3vh;
  justify-content: space-between;
  z-index: 0 !important;

  li button {
    border-radius: 50% !important;
    height: 2vh !important;
    width: 2vh !important;
  }
}

.ScrollableList {
  overflow: auto;
  white-space: nowrap;
}

.ScrollableImage {
  height: 55vh;
  max-height: 300px;
  width: 50vw;
  @media (max-width: $md) {
    height: 30vh;
    width: 100vw;
  }
}
