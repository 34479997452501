@import 'assets/globalStyles/variables';

.Container {
  height: 100%;
  position: relative;
  width: 100%;
  @media only screen and (min-width: $sm) {
    display: inline-block;
    vertical-align: top;
  }
}

.NotOverlay {
  display: none;
}

.Overlay {
  background: rgba(243, 247, 243, 0.7);
  border-radius: 15px;
  border-radius: 15px !important;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 1;

  & h1 {
    color: $secondary;
  }
}

.OverlayTitle {
  margin-top: 25%;
}

.Card {
  border-radius: 15px;
  box-shadow: 10px 10px 15px 8px rgba(0, 0, 0, 0.2);
  min-height: auto;
  width: 100%;
}

.CardMeta {
  overflow: hidden;
  // text-align: center;
  white-space: pre-line;
}

.CardText {
  justify-content: center;
  margin-top: 15px;
  min-height: 80px;
  overflow: scroll;
  padding: 2% 6%;
  padding-top: 0;
  text-align: justify;

  :global {
    .ant-card-meta-title {
      background-color: $stone-50;
      overflow: hidden;
      white-space: pre-line;
      width: 90%;
      z-index: 3;

      h3 {
        box-orient: vertical;
        display: box;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
  }
}

.Description {
  @media only screen and (min-width: $sm) {
    height: 150px;
    overflow: scroll;
  }
}

.Divider {
  margin: 15px 0;
  width: 100%;
}

.FullIcon {
  left: 0;
  margin-left: -25px;
  margin-right: -20px;
  margin-top: -30px;
  position: absolute;
  width: 25%;
  z-index: 3;
  @media only screen and (max-width: $md) {
    width: 20%;
  }
  @media only screen and (max-width: $sm) {
    margin-top: -20px;
    width: 25%;
  }
}

.PackCardImage {
  border-radius: 15px 15px 0 0 !important;
  border-width: 0 0 10px;
  height: 240px;
  object-fit: cover;
  width: 100%;
}

.CardImage {
  border-radius: 15px 15px 0 0 !important;
  height: 240px;
  object-fit: cover;
  width: 100%;
}

.BodyHorizontalLine {
  padding-left: -6%;
}

.HorizontalLine {
  margin-top: 0;
}

.PriceDetail {
  align-items: flex-start;
  box-shadow: none;
  display: flex;
  flex-flow: column;
  padding: 0 5%;

  & p {
    color: $stone-400;
    margin: 0;
  }
}

.SelectSpace {
  box-shadow: none;
  padding: 0 5% 5%;
  width: 100%;
}

.DescriptionSpace {
  box-shadow: none;
  justify-content: center;
  padding: 0 5% 5%;
  text-align: center;
  width: 100%;
}

.SelectButton {
  width: 100%;
}

.ShowMoreCollapse {
  :global {
    .ant-collapse-header {
      color: $primary !important;
      justify-content: center;
      padding-block: 6px !important;
      text-align: center;
    }

    .ant-collapse-arrow {
      color: $primary;
    }
  }
}

.ClosedTemporaryContainer {
  background-color: rgba(242, 109, 49, 0.7);
  height: fit-content;
  margin-top: 12.5%;
  max-width: 100%;
}

.ClosedTemporaryText {
  color: $stone-400 !important;
  line-height: normal;
  margin-top: 2.5%;
  text-align: center;
  word-spacing: 200px;
}

.CountBadge {
  :global {
    .ant-badge-count {
      border-radius: 100%;
      font-size: 18px;
      height: 28px;
      line-height: 28px;
      width: 28px;
    }
  }
}
