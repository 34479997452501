@import 'assets/globalStyles/variables';

.Title {
  color: $stone-800;
}

.Container {
  margin-right: -$main-padding-right;
}

.SuccessImage {
  height: 35vh;
  margin-bottom: -20vh;
  object-fit: cover;
  width: 100%;
  @media only screen and (min-width: $sm) {
    height: 50vh;
    margin-bottom: -30vh;
  }
}

.ReminderText {
  padding-top: 5vw;
}

.CardContainer {
  background-color: $secondary;
  margin-bottom: -80px;
  padding-top: 20px;
}
