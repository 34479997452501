@import 'assets/globalStyles/variables';

.Container {
  margin-bottom: 30px;
  width: 100%;
}

.Title {
  color: $stone-50;
  display: inline;
}

.AvailabilityCard {
  border-radius: 6px;
}

.MonthsAvailability {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.PrimaryButton {
  width: 100%;
}

.BuyDisclaimer {
  color: $stone-50;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
