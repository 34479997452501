@import 'assets/globalStyles/variables';

.Container {
  background: $primary;
  height: 64px;
  text-align: center;
}

.Logo {
  width: 150px;
}
