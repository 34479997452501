.MapContainer {
  height: 100%;
  width: 100%;
}

.Map {
  border: 0;
  border-radius: 12px;
  height: 100%;
  width: 100%;
}
