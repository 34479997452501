@import 'assets/globalStyles/variables';

.Container {
  margin-block: 24px;
  width: 100%;
}

.Card {
  border-radius: 10px;
  width: 90vw;
}

.CardText {
  font-size: 1.6vw;
  @media only screen and (max-width: $md) {
    font-size: large;
  }
}

.WarningIcon {
  color: red;
  font-size: 48px;
  margin-bottom: 18px;
}
