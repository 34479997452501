@import 'assets/globalStyles/variables';

.Container {
  margin-right: -$main-padding-right;
  margin-top: -$main-padding-top;
}

.Description {
  color: $primary;
  margin-top: 15%;
  text-align: left;
}

.List {
  margin: 5% 0 15%;
  text-align: left;
}

ul {
  list-style: none; /* Remove default bullets */
}

ul li::before {
  color: $primary;
  content: '\2022';
  display: inline-block;
  font-weight: bold;
  margin-left: -3em;
  width: 1em;
}
