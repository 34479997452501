@import 'assets/globalStyles/variables';

.Container {
  background: $primary;
  color: $stone-50;
  font-family: 'Barlow', sans-serif;
  text-align: center;
  width: 100%;
}

.ShareSocialMediaContainer {
  background: $stone-100;
  padding-bottom: 80px;
  @media only screen and (max-width: $sm) {
    background: $primary;
    padding-bottom: 0;
  }
}

.Row {
  align-items: start;
  margin: 24px 0;
}

.LogoRow {
  align-items: start;
}

.JoinEmailChainMessage {
  color: $stone-50;
  line-height: 1.2;
  margin-bottom: 60px;
  margin-top: 15%;
  @media only screen and (max-width: $sm) {
    font-size: x-large;
    margin-bottom: 30px;
    margin-top: 70px;
  }
}

.Schedule {
  font-weight: bolder;
}

.FooterImage {
  width: stretch;
}

.InputMail {
  background-color: transparent;
  border-color: $primary;
  border-radius: 5px;
  border-width: 2px;
  height: 65px;
  @media only screen and (max-width: $sm) {
    height: 45px;
    margin-bottom: 70px;
  }

  & input {
    background-color: transparent;
  }
}

.mailIcon {
  color: $primary;
  font-size: xx-large;
  @media only screen and (max-width: $sm) {
    font-size: large;
  }
}

.availabilityInfo {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  font-size: medium;
  justify-content: space-between;
  margin-top: 12px;
  padding: 0 12px;
  text-align: left;
  @media only screen and (min-width: $sm) {
    font-size: large;
    margin-top: 0;
  }

  p {
    font-size: medium;
    line-height: 1.4;
    margin-bottom: 2px;
    @media only screen and (min-width: $sm) {
      font-size: large;
    }
  }
}

.HelpFormContainer {
  padding: 0 12px;
  @media only screen and (min-width: $sm) {
    padding: 0;
  }
}

.TricaoMission {
  text-align: justify;
  @media only screen and (max-width: $sm) {
    padding-top: 40px;
  }

  p {
    font-size: medium;
    line-height: 1.5;
  }
}

.SocialMediaIcons {
  text-align: left;
  @media only screen and (max-width: $sm) {
    padding-top: 12px;
  }
}

.SocialMediaIcon {
  height: 40px;
  padding-right: 10%;
  width: 40px;
  @media only screen and (max-width: $sm) {
    height: 25px;
  }

  &:last-child {
    padding-right: 0;
  }
}

.SocialMediaImage {
  filter: invert(100%);
  height: 40px;
  width: 40px;
}

.LogoContainer {
  text-align: right;
  @media only screen and (max-width: $sm) {
    padding-top: 12px;
    text-align: left;
  }
}

.TricaoLogo {
  width: 14vw;
  @media only screen and (max-width: $sm) {
    width: 45vw;
  }
}

.LightWeight {
  font-weight: 300;
}

.Href {
  color: $eastern-blue-300;
  text-decoration: underline;
}

.HrefContainer {
  text-align: right;
  @media only screen and (max-width: $sm) {
    padding-top: 12px;
    text-align: left;
  }
}
