@import 'assets/globalStyles/variables';

.Container {
  position: relative;
}

.SloganContainer {
  bottom: 64px;
  position: absolute;

  @media screen and (max-width: $sm) {
    bottom: 80px;
  }
}

.Slogan {
  color: $stone-50;
  margin-bottom: 0 !important;
  max-width: 672px;
  text-align: center;
}

.BuyTicketsButtonCol {
  text-align: center;
  @media only screen and (min-width: $xl) {
    text-align: right;
  }
}

.BuyGiftCardButtonCol {
  text-align: center;
  @media only screen and (min-width: $xl) {
    text-align: left;
  }
}

.VideoContainer {
  position: relative;
}

.VideoContainer div video {
  min-height: 500px;
  object-fit: cover;
  width: 100%;
}

.PlayPauseContainer {
  bottom: 40px;
  position: absolute;
  right: 40px;
  @media only screen and (max-width: $sm) {
    display: none;
  }
}

.PlayPauseButton {
  backdrop-filter: blur(10px) !important;
  background: transparent !important;
  border: 1px solid transparent !important;
  height: 56px;
  opacity: 1;
  width: 56px;
  z-index: 1;

  &:hover {
    background: $stone-400 !important;
    border-color: $stone-50 !important;
    color: $stone-50 !important;
  }
}

.PlayPauseIcon {
  font-size: 2rem;
}

.LoadingOverlay {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  color: white;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.Loader {
  font-size: 125px;
  @media only screen and (max-width: $md) {
    font-size: 75px;
  }
}
