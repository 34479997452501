@import 'assets/globalStyles/variables';

.Card {
  border-radius: 10px;
  padding-block: 20px;
  padding-inline: 2vw;
  width: 75vw;
  max-width: 700px;
}

.CardTitle {
  text-align: start;
  @media only screen and (max-width: $lg) {
    text-align: center;
  }
}

.CardDescription {
  text-align: start;
  @media only screen and (max-width: $lg) {
    text-align: center;
  }
}

.DownloadTicketsButton {
  background-color: $primary;
  border-color: $primary;
  margin-top: 20px;
}
