@import 'assets/globalStyles/variables';

.Container {
  padding: 40px;
}

.TitleContainer {
  display: flex;
  margin-left: 10%;
}

.IconContainer {
  border-radius: 100%;
  height: 32px;
  padding: 4px;
  width: 32px;
}

.YellowIconContainer {
  @extend .IconContainer;
  background-color: $mandalay-500;
}

.GreenIconContainer {
  @extend .IconContainer;
  background-color: $fern-green-600;
}

.BlueIconContainer {
  @extend .IconContainer;
  background-color: $eastern-blue-800;
}

.Yellow {
  color: $mandalay-500;
}

.Green {
  color: $fern-green-600;
}

.Blue {
  color: $eastern-blue-800;
}

.Text {
  text-align: left;
}
