@import '../../../assets/globalStyles/breakpoints.scss';
@import 'assets/globalStyles/variables';

.Container {
  margin-top: 40px;
  padding-inline: 40px;
}

.Title {
  color: $primary;
  margin-bottom: 20px;
  text-align: center;
}

.ScrollableList {
  margin-top: 40px;
  overflow: auto;
  white-space: nowrap;
}

.ScrollableImage {
  height: 284px;
  width: 20vw;
}
