@import 'assets/globalStyles/variables.scss';

.Row{
  margin-bottom: 2em;
}

.ChangeDateButton{
  width: 100%;
  margin-bottom: 2em;
}
