@import 'assets/globalStyles/variables';

.Menu {
  width: fit-content;

  :global {
    .ant-menu {
      height: $navbar-height;
      max-width: 80vw;
    }
  }
}

.MenuOutlined {
  color: $stone-50;
  font-size: large;
  padding: 4px 15px;

  &:hover {
    color: $primary-800;
  }
}

.DrawerContainer {
  display: flex;
  flex-direction: column;
}

.Drawer {
  :global {
    .ant-drawer-header {
      background-color: $primary;
      color: $stone-50;

      div {
        button {
          color: $stone-50;
        }
      }
    }

    .ant-drawer-body {
      background-color: $primary;
      color: $stone-50;
    }
  }
}

.DrawerTitle {
  margin-bottom: 10px;
}

.DrawerItem {
  color: $stone-50;
  padding: 3%;
}

.DrawerItem:hover {
  color: $stone-400;
}
