@import 'assets/globalStyles/variables';

.Container {
  background-color: $fern-green-800;
  padding: 40px 128px;
  @media only screen and (max-width: $lg) {
    padding: 20px;
  }
}

.Title {
  color: $stone-50;
}

.DirectorContainer {
  margin-top: 40px;
}

.DirectorInfoContainer {
  margin-top: 5px;
  padding-left: 20vw;
  padding-right: 20vw;
  width: 100%;
  @media only screen and (min-width: $sm) {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }
}

.DirectorWords {
  text-align: left;

  p {
    color: $stone-50;
  }

  h4 {
    color: $stone-50;
  }

  @media only screen and (max-width: $lg) {
    margin-bottom: 40px;
  }
}

.DirectorPhoto {
  height: 354px;
  object-fit: contain;
  width: 100%;

  @media only screen and (max-width: $sm) {
    height: 100%;
  }
}
