@import 'assets/globalStyles/variables';

.Container {
  padding: 40px 40px 0;
}

.Title {
  color: $primary;
  text-align: start;
}

.Description {
  margin-bottom: 40px;
  margin-top: 20px;
  text-align: start;
}

.Image {
  border-radius: 12px;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
