@import 'assets/globalStyles/variables';

.Form {
  text-align: center;
  width: 100%;

  form {
    background: $stone-100 !important;
  }
}

.ComprisedCol {
  margin-bottom: 0;
  padding-bottom: 0;
}

.FormTitle {
  color: $primary;
  font-family: Barlow;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 16px;
  text-align: center;
  text-align: left;
}

.FormItem {
  margin-bottom: 0;
  width: 100%;
}

.Input {
  border-color: $slate-200;
}

.DatePicker {
  width: 100%;
}

.FormButton {
  width: 100%;
}
