@import 'assets/globalStyles/variables';
@import '../../../assets/globalStyles/breakpoints.scss';

.ScrollableItem {
  display: inline-block;
  height: 100%;
  margin-left: 4%;
  padding-top: 10px;

  &:last-child {
    margin-right: 4%;
  }
}

.RotateRight {
  rotate: 2deg;
}

.RotateLeft {
  rotate: -2deg;
}

.GrillScrollableItem {
  display: inline-block;
  margin-left: 4%;
  width: 25vw;
  @media (max-width: $md) {
    height: 30vh;
    width: 100vw;
  }

  &:last-child {
    margin-right: 4%;
  }
}

div::-webkit-scrollbar {
  display: none;
}

.ScrollableImage {
  height: fit-content;

  & img {
    border-radius: 12px;
    object-fit: cover;
    width: 100%;
  }
}

.TextContainer {
  background: rgb($primary-500, 0.4);
  border-radius: 10px;
  padding: 8px 12px;
  text-align: left;
  width: fit-content;
}

.Title {
  color: $stone-50;
}

.SubTitle {
  color: $stone-50;
  margin-bottom: 0;
}
