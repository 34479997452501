@import '~antd/dist/antd.css';
@import './variables';
@import './fonts';
@import './antd.scss';

body {
  font-family: 'Barlow', sans-serif;
  margin: 0;
}

p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Barlow;
  font-weight: 200;
  margin: 0;
}

h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 57.6px;
}

h2 {
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
}

h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h4 {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}

h5 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

h6 {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

p {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  // @media only screen and (max-width: $sm) {
  //   font-size: 12px;
  //   font-weight: 400;
  //   line-height: 16px;
  // }
}

// Ant Design Components
/* stylelint-disable */

#root {
  height: 100%;
  background-color: $stone-100;
}

/* stylelint-enable */
