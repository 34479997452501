/* stylelint-disable function-parentheses-space-inside */

// COLORS
$primary: #4c7637;
$primary-50: #f2f7ee;
$primary-300: #a4c98d;
$primary-500: #65984a;
$primary-700: #3b5b2b;
$primary-800: #334b29;

$secondary: #f26d31;
$secondary-50: #fafaf9;
$secondary-600: #e24714;

$mandalay-500: #cb9927;
$mandalay-600: #af781f;
$mandalay-800: #dbaf34;
$mandalay-900: #643c1f;
$shadow-800: #5a4c39;
$eastern-blue-300: #8cb9c4;
$eastern-blue-600: #2b7a8d;
$eastern-blue-800: #196471;
$strike-master-800: #734162;
$cardinal-600: #b9323c;
$brown: #5a4c39cc;
$brown-800: #483d2d;

$fern-green-300: #a4c98d;
$fern-green-500: #65984a;
$fern-green-600: #4c7637;
$fern-green-800: #334b29;

$stone-50: #fafaf9;
$stone-100: #f5f5f4;
$stone-400: #a8a29e;
$stone-500: #78716c;
$stone-900: #1c1917;

$slate-200: #e2e8f0;

// GRAY SCALE
$stone-50: #fafaf9;
$stone-100: #f5f5f4;
$stone-200: #e7e5e4;
$stone-300: #d6d3d1;
$stone-400: #a8a29e;
$stone-500: #78716c;
$stone-600: #57534e;
$stone-700: #44403c;
$stone-800: #292524;
$stone-900: #1c1917;
$stone-950: #0c0a09;

// GRADIENTS
$gradient-primary-02: #9fb1ea;
$gradient-secundary-02: #7cd8da;
$gradient-secundary-03: #c5feff;

$primary-gradient: linear-gradient(
  261.49deg,
  $primary 30.02%,
  $gradient-primary-02 126.62%
);
$secondary-gradient: linear-gradient(
  261.49deg,
  $secondary 30.02%,
  $gradient-secundary-02 112.03%,
  $gradient-secundary-03 126.62%
);

// BREAKPOINTS
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

// MEASURES
$navbar-height: 64px;
$main-padding-top: 40px;
$main-padding-right: 10px;
$main-padding-bottom: 40px;
