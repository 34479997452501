@import 'assets/globalStyles/variables';

.Container {
  @media only screen and (max-width: $sm) {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.Title {
  color: $stone-800;
  font-size: xx-large;
  font-weight: bold;
  @media only screen and (max-width: $sm) {
    font-size: x-large;
    margin-top: 1vh;
    padding: 0 3vw 1vh;
  }
}

.Date {
  color: $stone-800;
  margin-bottom: 3vh;
  @media only screen and (max-width: $sm) {
    font-size: large;
  }
}

.DateButtonCol {
  @media only screen and (max-width: $md) {
    padding-bottom: 16px;
  }
  @media only screen and (min-width: $md) {
    text-align: left;
  }
}

.TotalContainer {
  background-color: $stone-50;
  padding-bottom: 1%;
  padding-top: 1%;

  p {
    margin-bottom: -10px;
  }
}

.TotalDetail {
  align-items: flex-end;
  display: flex;
  flex-direction: column;

  & h2 {
    color: $stone-800;
    margin: 0;
  }
}

.TotalContainer {
  background-color: $stone-50;
  padding: 1%;
}

.SubTotal {
  color: $stone-300;
}
